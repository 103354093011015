/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {

                $(window).on("load resize", function equalHeights() {
                  if ($(window).width() > 1025) {
                    $('.matchHeight').each(function() {
                        var highestBox = 0;
                        $(this).find('.match-height').css("min-height", 0);
                        $(this).find('.match-height').each(function() {
                            if ($(this).outerHeight() > highestBox) {
                                highestBox = $(this).outerHeight();
                            }
                        });
                        $(this).find('.match-height').css("min-height", highestBox);
                    });
                  }
                }).resize();
                $(window).on('hashchange', function(e){
                  if($('body').find('.sectionOverview__content__body--products').length) {
                    equalHeights();
                  }
                });

                $(".pageHeader__anchorDown").on('click', function(event) {
                    if (this.hash !== "") {
                        event.preventDefault();
                        var hash = this.hash;

                        $('html, body').animate({
                            scrollTop: $(hash).offset().top - 90
                        }, 800, function() {

                            window.location.hash = hash;
                        });
                    }
                });

                $('.masonryGallery__carousel').flickity({
                    cellAlign: 'left',
                    contain: true,
                    imagesLoaded: true,
                    margin: 10,
                    pageDots: false
                });

                // $('.flickity-prev-next-button.next').on('click', function() {
                //   $('.masonryGallery__carousel').flickity('resize');
                // });
                //
                // $('.flickity-prev-next-button.previous').on('click', function() {
                //   $('.masonryGallery__carousel').flickity('resize');
                // });



                $(window).on('ready load', function () {
                  $('.masonryGallery__carousel').flickity('resize');
                });

                function sliderResize() {
                  $('.masonryGallery__carousel').flickity('resize');
                }

                setTimeout(function(){ sliderResize(); }, 500);
                setTimeout(function(){ sliderResize(); }, 1000);
                setTimeout(function(){ sliderResize(); }, 2000);
                setTimeout(function(){ sliderResize(); }, 4000);


                $('.lightbox__carousel').owlCarousel({
                    items: 1,
                    nav: true,
                    loop: false
                });

                $('.pageHeader__owlCarousel').owlCarousel({
                    items: 1,
                    nav: true
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired

                $(".nav-toggle").click(function(event) {
                    event.preventDefault();
                    var scrollTop = $(window).scrollTop();
                    $("body, .nav-wrapper, .nav-toggle").toggleClass("open");

                    if ($('body').hasClass('open')) {
                        $('body').css('top', -(scrollTop) + 'px');
                    } else {
                        var bodyTop = $('body').css('top').replace('px', '').replace('-', '');
                        $('body').css('top', '0');
                        $(window).scrollTop(bodyTop);
                    }
                });

                $(".search-toggle").click(function(event) {
                    event.preventDefault();
                    var scrollTop = $(window).scrollTop();
                    $('body, .search-wrapper, .toggle-wrapper').toggleClass('open');

                    $('#form__search input').focus();
                    setTimeout(function() { $('#form__search input').focus(); }, 100);

                    if ($('body').hasClass('open')) {
                        $('body').css('top', -(scrollTop) + 'px');
                    } else {
                        var bodyTop = $('body').css('top').replace('px', '').replace('-', '');
                        $('body').css('top', '0');
                        $(window).scrollTop(bodyTop);
                    }
                });

                function openLightBox(imgindex) {
                  var scrollTop = $(window).scrollTop();

                  var carouselindex = $('.lightbox__carousel').find('#lightbox_image_'+imgindex).parent().index();
                  $('.lightbox__carousel').trigger('to.owl.carousel', [carouselindex, 0]);
                  $('body, .lightbox').toggleClass('open');

                  if ($('body').hasClass('open')) {
                      $('body').css('top', -(scrollTop) + 'px');
                  } else {
                      var bodyTop = $('body').css('top').replace('px', '').replace('-', '');
                      $('body').css('top', '0');
                      $(window).scrollTop(bodyTop);
                  }
                }
                function closeLightbox() {
                  $('body, .lightbox').toggleClass('open');
                  var bodyTopClose = $('body').css('top').replace('px', '').replace('-', '');
                  $('body').css('top', '0');
                  $(window).scrollTop(bodyTopClose);
                }
                $(".lightbox-toggle, .img-lightbox-toggle").click(function(event) {
                    event.preventDefault();
                    if($('input[name="disablelightbox"]').length === 0) {
                      if($(this).hasClass('image') && $(this).attr('data-id')) { //open by click on image
                        openLightBox($(this).data('id'));
                      } else if($(this).find('.image').attr('data-id')) { //open by click on parent div
                        openLightBox($(this).find('.image').data('id'));
                      }
                    }
                });
                $('.closeLightbox').click(function(event) {
                  event.preventDefault();
                  closeLightbox();
                });

                $('ul.checkboxes a>label').click(function() {
                  window.location.href = $(this).parent().attr('href');
                });

                $(document).keyup(function(e) {
                     if (e.keyCode == 27) { // escape key maps to keycode `27`
                        $('body, .search-wrapper, .toggle-wrapper, .lightbox').removeClass('open');
                        var bodyTop = $('body').css('top').replace('px', '').replace('-', '');
                        $('body').css('top', '0');
                        $(window).scrollTop(bodyTop);
                    }
                });
                $(document).mouseup(function(e) {
                  if($('.lightbox').hasClass('open')) {
                    var container = $(".lightbox > .container");

                    // if the target of the click isn't the container nor a descendant of the container
                    if (!container.is(e.target) && container.has(e.target).length === 0) {
                      $('body, .search-wrapper, .toggle-wrapper, .lightbox').removeClass('open');
                      var bodyTop = $('body').css('top').replace('px', '').replace('-', '');
                      $('body').css('top', '0');
                      $(window).scrollTop(bodyTop);
                    }
                  }
                });

                var busy = false;
                $('.redirect_account').click(function(e) {
                  e.preventDefault();
                  window.location.href = $('input[name="account_link"]').val();
                });
                $('.add_to_wishlist').click(function(e) {
                  e.preventDefault();
                  var button = $(this);
                  if(!busy) {
                    if($(this).attr('data-product')) {
                      $.ajax({
                        url: wishlist.ajax_url,
                        type: 'POST',
                        data: {
                          action: 'update_wishlist',
                          product_id: $(this).data('product')
                        },
                        beforeSend: function() {
                          busy = true;
                          button.find('span').text('Verwerken..');
                        },
                        success: function(data) {
                          data = $.parseJSON(data);
                          busy = false;
                          if(data.result == 'success') {
                            if(button.hasClass('remove_wishlist')) {
                              button.removeClass('remove_wishlist');
                              button.find('span').text('Voeg toe');
                            } else {
                              button.addClass('remove_wishlist');
                              button.find('span').text('Verwijder');
                            }
                          }
                        },
                        error: function(errorThrown) {
                          alert('error wishlist update');
                          console.log(errorThrown);
                        }
                      });
                    } else {
                      alert('foutmelding wishlist');
                    }
                  }


                });

                function createMoreMenu(menu, width) {
                    if ($(window).width() > width) { //get body width without(!) scrollbar
                        var w = 0;
                        var mw = $(menu).parent().width(); //100 = width of 'more' item
                        var menuhtml = '';
                        $(menu).children().each(function() {
                            w += $(this).outerWidth(true);
                            if (mw < w && !$(this).find('a').hasClass('more')) {
                                menuhtml += $('<div>').append($(this).clone()).html();
                                $(this).remove();
                            }
                        });
                        if (menuhtml) {
                            $(menu + " .dropdown.more>ul").html('');
                            $(menu + " .dropdown.more>ul").append(menuhtml);
                            $(menu + " .dropdown.more").removeClass('hidden-lg-up').removeClass('hidden-lg-down');
                        }
                    }
                }

                $('header ul.more').each(function() {
                    var menu = '#' + $(this).attr('id');
                    var width = $(this).data('breakpoint') || 1025;
                    var originalMenu = $(menu).clone();
                    setTimeout(function() {
                        createMoreMenu(menu, width);
                        $(menu).removeClass('vh');
                        $(originalMenu).removeClass('vh');
                    }, 100);
                    $(window).on("resize", function() {
                        $(menu).replaceWith(originalMenu.clone());
                        createMoreMenu(menu, width);
                    });
                });

                setTimeout(function() {
                    $('input.icheck, input.ninja-forms-field').iCheck();
                }, 500);

                $("select.options").select2({
                    minimumResultsForSearch: Infinity
                });
            }
        },
        // Home page
        'page_template_template_contact': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
                function new_map( $el ) {

                	var $markers = $el.find('.marker');

                	var args = {
                		zoom		: 12,
                		center		: new google.maps.LatLng(0, 0),
                		mapTypeId	: google.maps.MapTypeId.ROADMAP,
                    disableDefaultUI: true,
                    //draggable: false,
                    //zoomControl: false,
                    scrollwheel: false,
                    //disableDoubleClickZoom: true
                	};

                	var map = new google.maps.Map( $el[0], args);

                	map.markers = [];

                	$markers.each(function(){
                    add_marker( $(this), map );
                	});
                	center_map( map );

                	return map;

                }

                function add_marker( $marker, map ) {

                	// var
                	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

                	var marker = new google.maps.Marker({
                		position	: latlng,
                		map			: map
                	});

                	map.markers.push( marker );

                	if( $marker.html() )
                	{
                		var infowindow = new google.maps.InfoWindow({
                			content		: $marker.html()
                		});

                		google.maps.event.addListener(marker, 'click', function() {
                			infowindow.open( map, marker );
                		});
                	}
                }

                function center_map( map ) {

                	var bounds = new google.maps.LatLngBounds();
                	$.each( map.markers, function( i, marker ){
                		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
                		bounds.extend( latlng );
                	});

                	if( map.markers.length == 1 ) {
                	    map.setCenter( bounds.getCenter() );
                	    map.setZoom( 12 );
                	} else {
                		map.fitBounds( bounds );
                	}
                }

                var map = null;

                $(document).ready(function(){
                	$('.acf-map').each(function(){
                		map = new_map( $(this) );
                	});
                });
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
